import "./src/styles/global.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/400.css";
import clevertap from 'clevertap-web-sdk'
clevertap.privacy.push({optOut: false}) // Set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({useIP: true})  // Set the flag to true, if the user agrees to share their IP data
clevertap.init(process.env.GATSBY_CLEVERTAP_ACCOUNT_ID) // Replace with values applicable to you. Refer below
export const onServiceWorkerUpdateReady = () => {
    window.location.reload()
}
